import HttpCommon from './HttpCommon'

import { ErrorWrapper, ResponseWrapper } from './util'
export default class ProfileService {
    constructor() {
        this.http = new HttpCommon('profile', true)
    }

    getProfile = async (data = {}) => {
        try {
            const response = await this.http.get('', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    createProfile = async (data = {}) => {
        try {
            console.log('createProfile', data);
            const response = await this.http.post('', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }

    updateProfile = async (data = {}) => {
        try {
            
            const response = await this.http.patch('', data)
            return new ResponseWrapper(response).data
        } catch (error) {
            throw new ErrorWrapper(error)
        }
    }
}